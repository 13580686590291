/** @module show cookies message & set cookie  */

import $ from 'jquery';

function createCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toGMTString()}`;
    document.cookie = `${name}=${value}${expires}; path=/`;
}

function readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function checkCookie() {
    if (!(readCookie('cookies_accepted') == 'yes' || sessionStorage.getItem('cookies_accepted') == 'no' )) {
        //$('#cookies-message').show();
    }
}

function closeCookieMessage() {
    createCookie('cookies_accepted', 'yes', 365);
    document.getElementById('cookies-message-container');
    // $('#cookies-message')
    //     .fadeOut()
    //     .css({ position: 'fixed' })
    //     .animate({ bottom: '10px' });
}

function clearCookie() {
    sessionStorage.setItem('cookies_accepted', 'no');
    document.getElementById('cookies-message-container');
    // $('#cookies-message')
    //     .fadeOut()
    //     .css({ position: 'fixed' })
    //     .animate({ bottom: '10px' });
}

window.addEventListener('load', () => {
    checkCookie();
});

window.closeCookieMessage = closeCookieMessage;
window.clearCookie = clearCookie;
