// import 'babel-polyfill';     // uncomment this if you want to use for...of, async/await
                                // or other things that require babel runtime
const $ = require('jquery');

import './modules/cookies';
import menuToggle from './modules/menuToggle';
import { isMobile } from './modules/isMobile';

import {TweenMax, TimelineLite, TweenLite, EasePack} from 'gsap';
import 'fullpage.js/dist/jquery.fullpage.min.js';
import './modules/home';

window.addEventListener('load', () => {

    var collapse = new menuToggle();
    collapse.init();

    $(function () {
        const mhPopup = $('#mh-popup');
        const bodyId = $('body').attr('id');
        const bodyClass = $('body').attr('class');

        $('a.go-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });

        $('#buy-now, #buy-now-2').on('click', function() {
            $(this).toggleClass('open');
        });

        // $('#buy-now a, #buy-now-2 a').on('click', function(e) {
        //     let url = $(this).attr('href');
        //     $('#confirm').attr('href', url);
        //     e.preventDefault();
        //     mhPopup.fadeIn('fast');
        //     $('body').css('overflow', 'hidden');
        // });

        $('#pharmacy-list li a').on('click', function(e) {
            let url = $(this).attr('href');
            $('#confirm').attr('href', url);
            e.preventDefault();
            mhPopup.fadeIn('fast');
            $('body').css('overflow', 'hidden');
        });

        $('.hide-modal').on('click', function() {
            mhPopup.fadeOut('fast');
            $('body').css('overflow', 'auto');
        });

        if (isMobile() == true) {
            $('#init-carousel').addClass('owl-carousel owl-theme');
            $('.owl-carousel').owlCarousel({
                loop: false,
                margin: 0,
                dots: false,
                nav: false,
                autoHeight: true,
                navText: [
                    '',
                    ''
                ],
                responsive :{
                    0: {
                        items:1
                    },
                    600: {
                        items:1
                    },
                    1000: {
                        items:1
                    }
                }
            });
            $('#swipe-vita').click(function() {
                $('.owl-carousel').trigger('next.owl.carousel');
            });
            $('#swipe-ruti').click(function() {
                $('.owl-carousel').trigger('prev.owl.carousel');
            });
        }

        var isIpad = {
            iPad: function() {
                return navigator.userAgent.match(/iPad/i);
            }
        }

        function fixedPackshot() {
            const sectionDownload = $('section.download');
            let fixmeTop = sectionDownload.offset().top,
                downloadHeight = sectionDownload.outerHeight(true);

            fixmeTop = fixmeTop - downloadHeight;

            $(window).bind('resize', () => {
                fixmeTop = sectionDownload.offset().top,
                downloadHeight = sectionDownload.outerHeight(true);

                fixmeTop = fixmeTop - downloadHeight;
            });

            $(window).bind('orientationchange', () => {
                fixmeTop = sectionDownload.offset().top,
                downloadHeight = sectionDownload.outerHeight(true);

                fixmeTop = fixmeTop - downloadHeight;
            });

            $(window).scroll(function() {
                let currentScroll = $(window).scrollTop();
                if (currentScroll >= fixmeTop) {
                    $('.fixed-part').css({
                        position: 'relative',
                        'margin-top': fixmeTop + 'px',
                        width: '100%'
                    });
                } else {
                    $('.fixed-part').css({
                        position: 'fixed',
                        'margin-top': 0,
                        width: 'calc(50% - 1px)'
                    });
                }
            });
        }

        if (bodyId == 'fixed-scroll' && isMobile() == false && innerWidth > 991) {
            fixedPackshot();
        }

        if (bodyId == 'fixed-scroll' && bodyClass == 'landscape' && isMobile() == true && innerWidth >= 1024 && isIpad.iPad() != null) {
            fixedPackshot();
        }

        if (bodyClass == 'rutinoscorbin' && isMobile() == true) {
            let str = $('.blister .medicine').html();
            let news = str.replace(':', ':<br />');
            news = news.replace('C:', 'C:<br />');
            news = news.replace('<br>', '<br /><br />');
            $('.blister .medicine').html(news);
        }

        if (bodyClass == 'products' && isMobile() == false) {
            const elements = [{
                    's1': $('#part-ruti .intro'),
                    's2': $('#part-vita .intro')
                },{
                    's1': $('#part-ruti .ingredients'),
                    's2': $('#part-vita .ingredients')
                },
                {
                    's1': $('#part-ruti .use'),
                    's2': $('#part-vita .use')
                },
                {
                    's1': $('#part-ruti .pack'),
                    's2': $('#part-vita .pack')
                },
                {
                    's1': $('#part-ruti .download'),
                    's2': $('#part-vita .download')
                }
            ];

            $.each(elements, function(index, value) {
                let heightRuti = value['s1'].innerHeight(),
                    heightVita = value['s2'].innerHeight(),
                    height = Math.round(Math.max(heightRuti, heightVita)) + 1;

                value['s1'].css('height', height);
                value['s2'].css('height', height);
            });

        }

    });

});
