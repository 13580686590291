/** @module home  */

window.jQuery = require("jquery");
window.$ = window.jQuery;
require('owl.carousel');
import { Elastic } from 'gsap';
import { isMobile } from './isMobile';

let animationID = 0;
let animationRunning = false;

let bodyClass = $('body').attr('class');

// Auto start animation
if (isMobile() == false && bodyClass == 'home') {
    let startAnimate = new TimelineMax({onComplete:firstScene});
}


const animationSpeed = 0.6; 
function firstScene() {
    let scene = new TimelineLite();


    scene
        .set($('#prevent span'), {marginRight: '0%'})
        .set($('#cure span'), {marginLeft: '0%'})
        .set($('#para-1'), {y: '-50%', opacity: 0})
        .set($('#para-2'), {y: '-50%', opacity: 0})
        .set($('#bg-1'), {opacity: 0})
        .set($('#bg-2'), {opacity: 0})
        .set($('#pack-ruti-1'), {x: '-50%', opacity: 0})
        .set($('#pack-ruti-2'), {x: '-40%', opacity: 0})
        .set($('#dot-start'), {scale: 0, opacity: 0})
        .set($('#dot-ruti'), {scale: 0, opacity: 0})
        .set($('#dot-vita'), {scale: 0, opacity: 0})
        .set($('#dot-camp'), {scale: 0, opacity: 0})
        .set($('#learn-more-1'), {scale: 0, opacity: 0, bottom: '12%'})
        .set($('#learn-more-2'), {opacity: 0, bottom: '-50%'})
        .set($('#learn-more-3'), {opacity: 0, bottom: '-50%'})
        .set($('#arrow-down'), {opacity: 0, y: '0%'})
        .set($('#why-rutinoscorbin, #why-vita'), {top: '200%'})
        .from($('#vertical-line'), .75*animationSpeed, {scaleY: 0, transformOrigin: 'center'}, '+='+(.7*animationSpeed))
        .from($('#prevent'), .9*animationSpeed, {scaleX: 0, transformOrigin: 'left', ease:Power4.easeInOut}, '-='+(.5*animationSpeed))
        .from($('#prevent span'), .65*animationSpeed, {marginRight: '10%', opacity: 0, ease:Power0.easeIn}, '-='+(.3*animationSpeed))
        .to($('#bg-1'), .2*animationSpeed, {opacity: 1}, '-='+(.4*animationSpeed))
        .from($('#cure'), .9*animationSpeed, {scaleX: 0, transformOrigin: 'left', ease:Power4.easeInOut}, '-='+(.6*animationSpeed))
        .from($('#cure span'), .65*animationSpeed, {marginLeft: '-10%', opacity: 0, ease:Power0.easeIn}, '-='+(.3*animationSpeed))
        .to($('#bg-2'), .2*animationSpeed, {opacity: 1}, '-='+(.4*animationSpeed))
        .to($('#para-1'), 1.5*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.3*animationSpeed))
        .to($('#para-2'), 1.5*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.7*animationSpeed))
        .to($('#pack-ruti-1'), .65*animationSpeed, {x: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(1.5*animationSpeed))
        .to($('#pack-ruti-2'), .65*animationSpeed, {x: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(1*animationSpeed))
        .to($('#dot-start'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-='+(1.4*animationSpeed))
        .to($('#dot-ruti'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-='+(1.3*animationSpeed))
        .to($('#dot-vita'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-='+(1.2*animationSpeed))
        .to($('#dot-camp'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-='+(1.1*animationSpeed))
        .to($('#learn-more-1'), .45*animationSpeed, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-='+(1.4*animationSpeed))
        .to($('#arrow-down'), .4*animationSpeed, {opacity: 1, ease:Power0.easeNone}, '-='+(1.45*animationSpeed))
        .to($('#dot-start'), .2*animationSpeed, {scale: 1.9, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.4*animationSpeed))
        .set($('#dot-start'), {className: '+=active'})
        ;

    return scene;
}

function secondScene() {
    let scene = new TimelineLite();

    animationID = 1;

    scene
        .set($('#why-ruti-title'), {y: '150%', opacity: 0})
        .set($('#why-ruti-para'), {y: '90%', opacity: 0})
        .set($('#icon-border-1, #icon-border-2, #icon-border-3'), {y: '70%', opacity: 0})
        .set($('#text-1, #text-2, #text-3'), {y: '40%', opacity: 0})
        .to($('#arrow-down'), 1*animationSpeed, {opacity: 0, y: '200%', ease:Power1.easeOut}, '-='+(0.2*animationSpeed))
        .set($('#learn-more-1, #learn-more-3'), {opacity: 0, bottom: '-50%'})
        .set($('#learn-more-2'), {scale: 1, bottom: '12%'})
        .to($('#learn-more-2'), .45*animationSpeed, {transformOrigin: 'center', opacity: 1, ease:Power1.easeOut}, '-='+(.4*animationSpeed))
        .to($('#dot-start'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.4*animationSpeed))
        .set($('#dot-start'), {className: '-=active'})
        .to($('#dot-ruti'), .2*animationSpeed, {scale: 1.9, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.4*animationSpeed))
        .set($('#dot-ruti'), {className: '+=active'})
        .to($('#why-rutinoscorbin'), 1.25*animationSpeed, {top: '-110px', transformOrigin: 'up', ease:Power1.easeOut}, '-='+(.95*animationSpeed))
        .to($('#why-vita'), 1.25*animationSpeed, {top: '200%', transformOrigin: 'down', ease:Power4.easeIn}, '-='+(1.25*animationSpeed))
        .to($('#dot-vita'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.5*animationSpeed))
        .set($('#dot-vita'), {className: '-=active'})
        .to($('#why-ruti-title'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.2*animationSpeed))
        .to($('#why-ruti-para'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.9*animationSpeed))
        .to($('#icon-border-1'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.8*animationSpeed))
        .to($('#text-1'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.7*animationSpeed))
        .to($('#icon-border-2'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.7*animationSpeed))
        .to($('#text-2'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.6*animationSpeed))
        .to($('#icon-border-3'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(0.6*animationSpeed))
        .to($('#text-3'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.5*animationSpeed))
        ;

    return scene;
}

function thirdScene() {
    let scene = new TimelineLite();

    animationID = 2;

    scene
        .set($('#why-vita-title'), {y: '150%', opacity: 0})
        .set($('#why-vita-para'), {y: '90%', opacity: 0})
        .set($('#icon-border-4, #icon-border-5, #icon-border-6'), {y: '70%', opacity: 0})
        .set($('#text-4, #text-5, #text-6'), {y: '40%', opacity: 0})
        .to($('#arrow-down'), 1*animationSpeed, {opacity: 0, y: '200%', ease:Power1.easeOut}, '-='+(.2*animationSpeed))
        .set($('#learn-more-1, #learn-more-2'), {opacity: 0, bottom: '-50%'})
        .set($('#learn-more-3'), {scale: 1, bottom: '12%'})
        .to($('#learn-more-3'), .45*animationSpeed, {transformOrigin: 'center', opacity: 1, ease:Power1.easeOut}, '-='+(.4*animationSpeed))
        .to($('#why-rutinoscorbin'), 1.25*animationSpeed, {top: '-200%', transformOrigin: 'up', ease:Power1.easeIn}, '-='+(.5*animationSpeed))
        .to($('#dot-start, #dot-ruti, #dot-camp'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.3*animationSpeed))
        .set($('#dot-start, #dot-ruti, #dot-camp'), {className: '-=active'})
        .to($('#dot-vita'), .2*animationSpeed, {scale: 1.9, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.2*animationSpeed))
        .set($('#dot-vita'), {className: '+=active'})
        .to($('#why-vita'), 1.25*animationSpeed, {top: '-110px', transformOrigin: 'up', ease:Power1.easeOut}, '-='+(.2*animationSpeed))
        .to($('#why-vita-title'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.2*animationSpeed))
        .to($('#why-vita-para'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.9*animationSpeed))
        .to($('#icon-border-4'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.8*animationSpeed))
        .to($('#text-4'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.7*animationSpeed))
        .to($('#icon-border-5'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.7*animationSpeed))
        .to($('#text-5'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.6*animationSpeed))
        .to($('#icon-border-6'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.6*animationSpeed))
        .to($('#text-6'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.5*animationSpeed))
        ;

    return scene;
}

function fourthScene() {
    let scene = new TimelineLite();

    animationID = 3;

    scene
        .to($('#why-rutinoscorbin'), 1.25*animationSpeed, {top: '-200%', transformOrigin: 'up', ease:Power1.easeIn}, '-='+(.2*animationSpeed))
        .to($('#why-vita'), 1.25*animationSpeed, {top: '-200%', transformOrigin: 'up', ease:Power1.easeIn}, '-='+(.2*animationSpeed))
        .to($('#dot-start'), .2*animationSpeed, {scale: 0, transformOrigin: 'center', opacity: 0, ease:Power0.easeNone}, '-='+(.4*animationSpeed))
        .to($('#dot-ruti'), .2*animationSpeed, {scale: 0, transformOrigin: 'center', opacity: 0, ease:Power0.easeNone}, '-='+(.3*animationSpeed))
        .to($('#dot-vita'), .2*animationSpeed, {scale: 0, transformOrigin: 'center', opacity: 0, ease:Power0.easeNone}, '-='+(.2*animationSpeed))
        .to($('#dot-camp'), .2*animationSpeed, {scale: 0, transformOrigin: 'center', opacity: 0, ease:Power0.easeNone}, '-='+(.1*animationSpeed))
        .to($('#vertical-line'), .3*animationSpeed, {scaleY: 0, transformOrigin: 'center'}, '-='+(.1*animationSpeed))
        .to($('#arrow-down'), .3*animationSpeed, {scaleY: 0, transformOrigin: 'center', opacity: 0, ease:Power0.easeNone}, '-='+(.1*animationSpeed))
        .to($('#learn-more-1, #learn-more-2, #learn-more-3'), .2*animationSpeed, {scale: 0, transformOrigin: 'center', opacity: 0}, '-='+(.2*animationSpeed))
        ;

    return scene;
}

function showSecondSlide() {
    let scene = new TimelineLite();

    scene
        .set($('#section0'), {overflow: 'hidden'})
        .set($('#second-title'), {y: '150%', opacity: 0})
        .set($('#second-para'), {y: '90%', opacity: 0})
        .set($('#box-1, #box-2'), {y: '20%', opacity: 0})
        .set($('#box-title-1, #box-title-2'), {y: '50%', opacity: 0})
        .set($('#btn-1, #btn-2'), {scale: 0, opacity: 0})
        .set($('.arrow-right'), {opacity: 0, x: '-100px', y: '-50px'})
        .to($('#second-title'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '+='+(1*animationSpeed))
        .to($('#second-para'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.9*animationSpeed))
        .to($('#box-1'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.7*animationSpeed))
        .to($('#box-title-1'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.65*animationSpeed))
        .to($('#btn-1'), .45*animationSpeed, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-='+(.65*animationSpeed))
        .to($('#box-2'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.6*animationSpeed))
        .to($('#box-title-2'), 1*animationSpeed, {y: '0%', opacity: 1, ease:Circ.easeOut}, '-='+(.55*animationSpeed))
        .to($('#btn-2'), .45*animationSpeed, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-='+(.55*animationSpeed))
        ;

    return scene;
}

function showFirstSlide() {
    let scene = new TimelineLite();

    animationID = 0;

    scene
        .to($('#why-rutinoscorbin, #why-vita'), 1.25*animationSpeed, {top: '200%', transformOrigin: 'down', ease:Power4.easeIn}, '-='+(.2*animationSpeed))
        .to($('#vertical-line'), .75*animationSpeed, {scaleY: 1}, '-='+(.7*animationSpeed))
        .set($('#dot-start, #dot-ruti, #dot-vita, #dot-camp'), {opacity: 1})
        .set($('#learn-more-2, #learn-more-3'), {scale: 0, opacity: 0, bottom: '12%'})
        .set($('#learn-more-1'), {scale: 1, opacity: 1, bottom: '12%'})
        .set($('#arrow-down'), {opacity: 1, y: '0%'})
        .to($('#dot-start'), .2*animationSpeed, {scale: 1.9, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.4*animationSpeed))
        .set($('#dot-start'), {className: '+=active'})
        .to($('#dot-ruti, #dot-vita, #dot-camp'), .2*animationSpeed, {scale: 1, transformOrigin: 'center', ease:Power0.easeNone}, '-='+(.4*animationSpeed))
        .set($('#dot-ruti, #dot-vita, #dot-camp'), {className: '-=active'})
    ;

    return scene;
}

function showViatminScene() {
    let scene = new TimelineLite();

    animationID = 3;

    scene
        .set($('#section0'), {overflow: 'none'})
        .to($('#why-rutinoscorbin'), 1.25, {top: '-200%', transformOrigin: 'up', ease:Power1.easeIn}, '-=.5')
        .to($('#vertical-line'), .75, {scaleY: 1, transformOrigin: 'center'}, '-=.2')
        .to($('#why-vita'), 1.25, {top: '-110px', transformOrigin: 'down', ease:Power1.easeOut}, '-=.2')
        .set($('#dot-start, #dot-ruti, #dot-vita, #dot-camp'), {opacity: 1})
        .set($('#learn-more-3'), {scale: 1, opacity: 1, bottom: '12%'})
        .set($('#learn-more-1, #learn-more-2'), {scale: 0, opacity: 0, bottom: '12%'})
        .to($('#dot-start'), .2, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-=.7')
        .to($('#dot-ruti'), .2, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-=.6')
        .to($('#dot-vita'), .2, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-=.5')
        .to($('#dot-camp'), .2, {scale: 1, transformOrigin: 'center', opacity: 1, ease:Power0.easeNone}, '-=.4')
        .to($('#dot-vita'), .2, {scale: 1.9, transformOrigin: 'center', ease:Power0.easeNone}, '-=.3')
        .set($('#dot-vita'), {className: '+=active'})
        .set($('#dot-start, #dot-ruti, #dot-camp'), {className: '-=active'})
    ;

    return scene;
}

function enterAnimateButton(item) {
    let scene = new TimelineLite();

    scene
        .to($('#' + item), .3, {scale: 0, transformOrigin: 'center',  ease:Power0.easeNone}, '+=.2')
        .to($('#' + item).siblings('.box-title'), .3, {y: '20px', transformOrigin: 'down',  ease:Power1.easeOut}, '-=.2')
        .to($('#' + item).siblings('.arrow-right'), .3, {x: 0, opacity: 1, transformOrigin: 'right', ease:Power1.easeOut}, '-=.2')
    ;

    return scene;
}

function leaveAnimateButton(item) {
    let scene = new TimelineLite();

    scene
        .to($('#' + item).siblings('.box-title'), .3, {y: '0', transformOrigin: 'up',  ease:Power1.easeOut}, '+=.1')
        .to($('#' + item).siblings('.arrow-right'), .3, {x: '100px', opacity: 0, transformOrigin: 'right', ease:Power1.easeOut}, '-=.2')
        .to($('#' + item), .3, {scale: 1, transformOrigin: 'center',  ease:Power0.easeNone}, '-=.2')
        .set($('#' + item).siblings('.arrow-right'), {opacity: 0, x: '-100px'})
    ;

    return scene;
}

function runCustomAnimation(nextAnimation) {
    if (animationRunning)
        return false;

    if ((nextAnimation + animationID) > 3 || (nextAnimation + animationID) < 0)
        return true;

    animationRunning = true;
    animationID += nextAnimation;

    if (animationID == 0) {
        let animTime = 0;
        if (isMobile() == false) {
            animTime = showFirstSlide().duration();
        }
        animTime = animTime * 1000;
        setTimeout(function() {
            animationRunning = false;
        }, animTime);
    }

    if (animationID == 1) {
        let animTime = 0;
        if (isMobile() == false) {
            animTime = secondScene().duration();
        }
        animTime = animTime * 1000;
        setTimeout(function() {
            animationRunning = false;
        }, animTime);
    }

    if (animationID == 2) {
        let animTime = 0;
        if (isMobile() == false) {
            animTime = thirdScene().duration();
        }
        animTime = animTime * 1000;
        setTimeout(function() {
            animationRunning = false;
        }, animTime);
    }

    if (animationID == 3) {
        fourthScene();
        let animTime = 0;
        if (isMobile() == false) {
            animTime = fourthScene().duration();
        }
        animTime = animTime * 1000;
        setTimeout(function() {
            animationRunning = false;
            $.fn.fullpage.moveTo(2);
            showSecondSlide();
        }, animTime);
    }

    return false;
}

$(document).keydown(function(e) {
    if (e.keyCode == 38 || e.keyCode == 33) {
        runCustomAnimation(-1);
        return false;
    }
});

$(document).ready(function() {
    // Manual start animations
    $('#dot-start').click(function(e) {
        e.preventDefault();
        showFirstSlide();
    });
    $('#dot-ruti').click(function(e) {
        e.preventDefault();
        secondScene();
    });
    $('#dot-vita').click(function(e) {
        e.preventDefault();
        thirdScene();
    });
    $('#dot-camp').click(function(e) {
        e.preventDefault();
        let animTime = 0;
        if (isMobile() == false) {
            animTime = fourthScene().duration();
        }
        animTime = animTime * 1000;
        setTimeout(function() {
            animationRunning = false;
            $.fn.fullpage.moveTo(2);
            showSecondSlide();
        }, animTime);
    });

	if (isMobile() == false) {

        let bodyId = $('body').attr('id');

        if (bodyId == 'full') {
            $('.go-top').on('click', function() {
                $.fn.fullpage.moveTo(1);
                showFirstSlide();
            });
        }

        let arrowTl = new TimelineMax({repeat: -1});

        arrowTl.to('#arrow-down', 0.75, {y: 30, ease: Back.easeIn, yoyo: true, repeat: 1, delay: 1.5});

        $('#fullpage').fullpage({
            navigation: false,
            navigationPosition: 'right',
            navigationTooltips: ['Produkty', 'Kampania', 'Stopka'],

            css3: true,
            scrollingSpeed: 700,
            fitToSection: false,
            fitToSectionDelay: 500,
            autoScrolling: true,
            verticalCentered: true,
            sectionsColor : ['#fff', '#fff', '#fff'],

            loopBottom: false,
            loopTop: false,
            loopHorizontal: true,
            continuousVertical: false,
            continuousHorizontal: false,
            scrollHorizontally: false,
            interlockedSlides: false,
            dragAndMove: false,
            offsetSections: false,
            resetSliders: false,
            fadingEffect: false,
            scrollOverflow: false,
            scrollOverflowReset: false,
            scrollOverflowOptions: null,
            touchSensitivity: 15,
            normalScrollElements: '.scrollable-element',
            normalScrollElementTouchThreshold: 5,
            bigSectionsDestination: 'top',
            responsiveWidth: 1280,

            lazyLoading: true,

            onLeave: function(index, nextIndex, direction) {
                let leavingSection = $(this);

                if (index == 1 && isMobile() == false) {
                    return runCustomAnimation(nextIndex - index);
                }

                if (index == 2 && direction == 'up' && isMobile() == false) {
                    return showViatminScene();
                    animationID = 3;
                }

                if (index == 2 && direction == 'down' && isMobile() == false) {
                    $('#section1').css('oveflow', 'hidden');
                }

                if (index == 3 && direction == 'up' && isMobile() == false) {
                    $('#section1').css('oveflow', 'none');
                }

            },
            afterLoad: function(anchorLink, index){
                let loadedSection = $(this);

                if (index == 1 && isMobile() == false) {
                    $(window).bind('mousewheel DOMMouseScroll', function(e){
                        if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
                            runCustomAnimation(-1);
                        } else{
                            runCustomAnimation(1);
                        }
                    });
                }
            }
        });
    }

    let box = $('.second-slide .box');

    box.mouseenter(function() {
        let id = $(this).data('attr');
        enterAnimateButton(id);
    });

    box.mouseleave(function() {
        let id = $(this).data('attr');
        leaveAnimateButton(id);
    });

    let iconBorder = $('.first-slide .icon-border').innerWidth();
    $('.first-slide .icon-border').css('height', iconBorder);
});
