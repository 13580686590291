import $ from 'jquery';

export default class MenuToggle {
    init() {
        this.cacheDom();
        this.bindEvents();
    }

    cacheDom() {
        this.header = $('#header');
        this.element = $('.navbar-toggle');
        this.nav = $('.main-menu nav');
        this.span = $('.main-menu span.text');
    }

    bindEvents() {
        this.element.bind('click', () => this.toggle());
    }

    toggle(e) {
        if (this.element.hasClass('active')) {
            this.element.removeClass('active');
            this.header.removeClass('active');
            this.nav.hide();
            this.span.show();
            $('body').css('overflow', 'inherit');
            $('.content, footer').css('display', 'inherit');
        } else {
            this.element.addClass('active');
            this.header.addClass('active');
            this.nav.show();
            this.span.hide();
            $('body').css('overflow', 'hidden');
            $('.content, footer').css('display', 'none');
        }
    }
}
